import { useToast } from 'vue-toastification';

import { router } from './routes';
import { Action } from './components/base/CommandPalette.vue';

const toast = useToast();

const linkedResourcesProvider: Record<
  string,
  (id: string, id2?: string) => Action[]
> = {
  publications: (id, isbn) => [
    {
      label: 'Create new license for publication',
      action: () => {
        router.push({
          name: 'new_license',
          params: { id: id },
        });
      },
    },
    {
      label: "Explore publication's licenses",
      action: () => {
        router.push({
          name: 'licenses',
          query: { entity: id, target: isbn },
        });
      },
    },
    {
      label: "Explore publication's shares",
      action: () => {
        router.push({
          name: 'publication-shares',
          params: { id },
        });
      },
    },
    {
      label: "Edit publication's metadata",
      action: () => {
        router.push({
          name: 'publications-metadata-edit',
          query: { entity: id },
        });
      },
    },
  ],
  'share/metadata': (id) => [
    {
      label: 'Explore the publication',
      action: () => {
        router.push({
          name: 'publication',
          params: { id },
        });
      },
    },
  ],
  periodicals: (id) => [
    {
      label: 'Create a new subscription for the periodical',
      action: () => {
        router.push({
          name: 'new_periodical_subscription',
          params: { id },
        });
      },
    },
  ],
  namespaces: (id) => [
    {
      label: "Explore the namespace's publications",
      action: () => {
        router.push({
          name: 'publications',
          query: { namespaceId: id },
        });
      },
    },
    {
      label: "Explore the namespace's licenses",
      action: () => {
        router.push({
          name: 'licenses',
          query: { namespaceId: id },
        });
      },
    },
    {
      label: "Explore the namespace's related namespaces",
      action: () => {
        router.push({
          name: 'related-namespaces',
          params: { id },
        });
      },
    },
  ],
};

const actionsProviders = (type: string, id: string, id2?: string): Action[] => {
  const provider: ((id: string, id2?: string) => Action[]) | null =
    linkedResourcesProvider?.[type] ?? null;
  return provider ? provider(id, id2) : [];
};

const commonActions = (type: string, id: string): Action[] => {
  const singularType = type.slice(0, -1);
  return [
    {
      label: `Copy ${singularType}'s ID`,
      action: () => {
        navigator.clipboard.writeText(id);
        toast.info(`Copied the current ${singularType}'s ID to clipboard`);
      },
    },
  ];
};

export const getActions = (
  type: string,
  id: string,
  id2?: string
): Action[] => {
  let extraActions: Action[] = [];
  let typeUsed = type;

  if (['publication-sharess', 'publications-metadata-edits'].includes(type)) {
    typeUsed = 'publications';
    extraActions = actionsProviders('share/metadata', id);
  }

  return [
    ...commonActions(typeUsed, id),
    ...extraActions,
    ...actionsProviders(typeUsed, id, id2),
  ];
};
