<template>
  <message-confirmation
    :isHidden="hideAlertMessage"
    titleInfoMessage="Creating New License/s"
    :bodyInfoMessage="messageBodyInfoAlert"
    buttonConfirmMessage="Yes, create"
    buttonCancelMessage="Cancel"
    :showCancelButton="true"
    variant="alert"
    @answerUser="answerAlertMessage"
  ></message-confirmation>
  <message-confirmation
    :isHidden="hideInfoMessage"
    titleInfoMessage="Total Number of Created License/s"
    :bodyInfoMessage="messageBodyInfo"
    buttonConfirmMessage="Confirm"
    buttonCancelMessage="Cancel"
    @answerUser="answerInfoMessage"
  ></message-confirmation>

  <div class="space-y-8 relative">
    <div class="section md:flex md:space-x-5">
      <div class="md:flex-none md:w-1/3 text-center md:text-left">
        <h1 class="text-gray-500 text-sm mb-5">
          Select the destination namespace and the Excel file to create the
          licenses from.
        </h1>
      </div>
      <div
        class="flex flex-col rounded bg-white overflow-hidden p-4 md:w-2/3 lg:p-6"
      >
        <namespace-selector v-model="namespaceSelectedRef" />

        <div v-if="namespaceSelectedRef" class="flex-grow w-full">
          <div class="space-y-1">
            <div class="flex-grow w-full flex justify-end mb-4">
              <div class="grid justify-items-center">
                <file-upload
                  @uploaded="handleUploadedExcel"
                  accept=".xlsx"
                  btsize="m"
                  class="mt-3"
                  text="Select Excel File"
                />
              </div>
            </div>
            <div class="flex gap-x-2 justify-end">
              <div class="underline text-sm">
                <a href="/licenses/licensesTemplate_en.xlsx"
                  >[Download Template]</a
                >
              </div>
            </div>
            <div class="mt-4">
              <warning-display
                class="mt-3"
                :warning="warningsToDisplay"
                headerMessage="Warning/s Found in the Excel File"
                :footerMessage="warningMessageFooter"
              ></warning-display>
            </div>
            <div class="mt-4">
              <error-display
                class="mt-3"
                :error="errorsToDisplay"
                headerMessage="Error/s Found in the Excel File"
                :footerMessage="errorMessageFooter"
              ></error-display>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template
      v-if="
        loading ||
        (parseExcelResult?.success &&
          parseExcelResult?.entries &&
          parseExcelResult.entries.length > 0)
      "
    >
      <div class="p-4 rounded bg-white">
        <success-display :hide="!readingMetadataSuccess">
          {{ registersRead }}
        </success-display>
        <success-display :hide="!creatingLicensesSuccess">
          {{ messageSuccessLicencesCreated }}
        </success-display>
        <div class="min-w-full text-end" v-if="loading">Loading...</div>
        <div
          class="overflow-x-auto min-w-full flex flex-col rounded-lg bg-white overflow-hidden dark:text-gray-100 dark:bg-gray-800"
          v-else-if="
            parseExcelResult?.success &&
            parseExcelResult?.entries &&
            parseExcelResult.entries.length > 0
          "
        >
          <div class="p-2 my-2 rounded-lg bg-gray-50 dark:bg-gray-700/50 px-5">
            <h3 class="font-semibold my-2">Namespace</h3>
            <h4
              class="text-sm font-medium text-gray-500 dark:text-gray-400 my-2"
            >
              {{ namespaceSelectedRef?.name }}
            </h4>
            <error-display
              class="mt-3"
              :error="serverErrors"
              headerMessage="Server Errors."
            ></error-display>
          </div>

          <Table
            center-header
            :headers="
              [0, 1, 2, 3, 4, 5].map((i) =>
                formatTableHeader(keysOriginalTemplateHeaders[i])
              )
            "
          >
            <tr
              v-for="entry of parseExcelResult.entries"
              :key="entry.orderLicensesDataCandidate.isbn"
              class="border-b border-gray-200"
            >
              <td class="p-3 text-center">
                <router-link
                  :to="{
                    name: 'publication',
                    params: {
                      id: entry.orderLicensesDataCandidate.entityId,
                    },
                  }"
                  target="_blank"
                  ><strong>{{
                    entry.orderLicensesDataCandidate[headers_values.isbn]
                  }}</strong></router-link
                >
              </td>
              <td class="p-3 text-center">
                {{
                  entry.orderLicensesDataCandidate[
                    headers_values.number_of_loans
                  ] ?? 'Unlimited'
                }}
              </td>
              <td class="p-3 text-center">
                {{
                  entry.orderLicensesDataCandidate[
                    headers_values.license_duration
                  ] ?? 'Never Expired'
                }}
              </td>
              <td class="p-3 text-center">
                {{
                  entry.orderLicensesDataCandidate[
                    headers_values.concurrency
                  ] ?? 'Unlimited'
                }}
              </td>
              <td class="p-3 text-center">
                {{
                  entry.orderLicensesDataCandidate[
                    headers_values.loan_duration
                  ] ?? 'Unbounded'
                }}
              </td>
              <td class="p-3 text-center">
                {{
                  entry.orderLicensesDataCandidate[
                    headers_values.licenses_number
                  ]
                }}
              </td>
            </tr>
          </Table>
        </div>
      </div>

      <div class="flex justify-end">
        <dm-button
          id="create-upload-publications"
          variant="danger"
          size="xL"
          class="mt-4"
          :enabled="enableLicensesToCreateButton"
          @click.prevent="createLicensesPerform()"
          >Create License/s</dm-button
        >
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { Ref, computed, inject, ref } from 'vue';
import XLSX from 'xlsx';
import dayjs from 'dayjs';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import { useToast } from 'vue-toastification';
import Table from '../../base/Table.vue';

import { graphql } from '../../../__generated__/gql';
import { LicenseSetInput } from '../../../__generated__/graphql';

import {
  nonEmptyIsbnAndRightFormatValidator,
  numberOfLicensesAndRightQuantityValidator,
  optionalNumberValidator,
} from './validators';
import MessageConfirmation from '../../base/DialogMessage.vue';
import FileUpload from '../../base/FileUpload.vue';
import ErrorDisplay from '../../ErrorDisplay.vue';
import WarningDisplay from '../../WarningDisplay.vue';
import SuccessDisplay from '../../SuccessDisplay.vue';
import NamespaceSelector from '../../namespaces/NamespaceSelector.vue';

interface headers {
  [key: string]: any;
}
interface ExcelEntryInformation {
  message: string;
  entry?: number;
  identifier?: string;
  header?: string;
}
interface ExcelEntryError extends ExcelEntryInformation {}
interface ExcelEntryWarning extends ExcelEntryInformation {}
interface ParseExcelResult {
  entries?: any;
  errors?: (ExcelEntryError | string)[];
  warnings?: (ExcelEntryWarning | string)[];
  success: boolean;
}

interface terms {
  checkouts: number | null;
  concurrency: number | null;
  expires?: any;
  length: number | null;
  capLoanExpirationToLicense: boolean;
}
interface protection {
  devices: number;
  copy: number;
  print: number;
  tts: true;
}

const toast = useToast();
const apolloClient = inject(
  DefaultApolloClient
) as ApolloClient<NormalizedCacheObject>;

const headers_values: headers = {
  licenses_number: ['number_of_licenses'],
  isbn: ['isbn'],
  number_of_loans: ['number_of_loans'],
  license_duration: ['license_duration'],
  concurrency: ['concurrency'],
  loan_duration: ['loan_duration'],
};
const MAX_ERRORS_TO_DISPLAY = 10;
const MAX_WARNINGS_TO_DISPLAY = 10;
const MAX_LICENSES_BEFORE_WARNING = 10;

const namespaceSelectedRef: Ref<{ id: string; name: string } | null> =
  ref(null);
const serverErrors = ref();
const messageBodyInfo = ref();
const parseExcelResult = ref<ParseExcelResult | null>();
const keysOriginalTemplateHeaders: Ref<Array<string>> = ref([]);
const loading = ref(false);
const readingMetadataSuccess = ref(false);
const creatingLicensesSuccess = ref(false);
const enableLicensesToCreateButton = ref(true);
const hideAlertMessage = ref(true);
const hideInfoMessage = ref(true);
const searchPublicationsByIsbnsQuery = graphql(`
  query publicationsByISBNs($isbns: [PublicationIdentifier!]!) {
    publications: publicationsByISBNs(isbns: $isbns) {
      id
      isbn
      metadata {
        author {
          name
        }
      }
      fulfillmentMethods
      cover {
        href
      }
    }
  }
`);
const createLicensesQuery = graphql(`
  mutation CreateLicenses(
    $namespaceId: ID!
    $source: String!
    $entries: [LicenseSetInput!]!
  ) {
    createLicenses(
      input: { namespaceId: $namespaceId, source: $source, entries: $entries }
    ) {
      numberOfCreatedLicenses
      success
    }
  }
`);

const registersRead = computed(() => {
  if (
    parseExcelResult?.value?.entries &&
    parseExcelResult.value.entries.length > 0
  ) {
    const messageQuantity = parseExcelResult.value.entries.length;
    const wordQuantity = messageQuantity > 1 ? ` entries have` : ` entry has`;
    return `${messageQuantity} ${wordQuantity} been processed.`;
  }
});

const errorsToDisplay = computed(() => {
  if (
    parseExcelResult?.value?.errors &&
    parseExcelResult.value.errors.length > 0
  ) {
    const errors = parseExcelResult.value.errors.map(
      (error: ExcelEntryError | string) => {
        if (typeof error === 'string') {
          return error;
        }
        return [
          `${
            error.identifier || error.entry
              ? `${error.identifier || error.entry} -`
              : ''
          }`,
          error.header ? `${error.header} :` : '',
          `${error.message}`,
        ].join(' ');
      }
    );
    return errors;
  }
});

const errorMessageFooter = computed(() => {
  if (
    parseExcelResult.value &&
    parseExcelResult.value.errors &&
    parseExcelResult.value.errors.length > 0
  ) {
    let errorMessage = '';

    if (parseExcelResult.value.errors.length > MAX_ERRORS_TO_DISPLAY) {
      const nonDisplayedErrorsCount =
        parseExcelResult.value.errors.length - MAX_ERRORS_TO_DISPLAY;
      errorMessage = ` and ${nonDisplayedErrorsCount} more error${
        nonDisplayedErrorsCount > 1 ? `s` : ``
      }.`;
    }
    return (
      errorMessage +
      ` Please correct ${
        parseExcelResult.value.errors.length == 1 ? `it` : `them`
      } and try it again.`
    );
  }
});

const warningsToDisplay = computed(() => {
  if (
    parseExcelResult?.value?.warnings &&
    parseExcelResult.value.warnings.length > 0
  ) {
    const warnings = parseExcelResult.value.warnings.map(
      (warning: ExcelEntryWarning | string) => {
        if (typeof warning === 'string') {
          return warning;
        }

        return [
          `${
            warning.identifier || warning.entry
              ? `${warning.identifier || warning.entry} -`
              : ''
          }`,
          warning.header ? `${warning.header} :` : '',
          `${warning.message}`,
        ].join(' ');
      }
    );
    return warnings;
  }
});

const warningMessageFooter = computed(() => {
  if (
    parseExcelResult?.value?.warnings &&
    parseExcelResult.value.warnings.length > 0
  ) {
    let errorMessage = '';
    if (parseExcelResult.value.warnings.length > MAX_WARNINGS_TO_DISPLAY) {
      const nonDisplayedWarningsCount =
        parseExcelResult.value.warnings.length - MAX_WARNINGS_TO_DISPLAY;
      errorMessage = ` and ${nonDisplayedWarningsCount} more warning${
        nonDisplayedWarningsCount > 1 ? `s` : ``
      }`;
    }
    return errorMessage;
  }
});

const messageBodyInfoAlert = computed(() => {
  return `Are you sure you want to create ${parseExcelResult.value?.entries?.reduce(
    (total: number, actualEntry: any): number =>
      Number(actualEntry.orderLicensesDataCandidate.number_of_licenses) + total,
    0
  )} license/s ?`;
});

const messageSuccessLicencesCreated = computed(() => {
  return `${messageBodyInfo.value}.`;
});

async function createLicenses(
  namespaceId: string,
  source: string,
  entries: LicenseSetInput[]
): Promise<any> {
  return apolloClient.mutate({
    mutation: createLicensesQuery,
    variables: { namespaceId, source, entries },
  });
}

async function getPublications(isbns: Array<string>): Promise<any> {
  return apolloClient.query({
    query: searchPublicationsByIsbnsQuery,
    variables: { isbns: isbns },
    fetchPolicy: 'network-only',
  });
}

async function parseExcel(entries: any): Promise<ParseExcelResult> {
  const rx = /\s*([^\s\(\)\*][^\(\)\*]*[^\s\(\)\*])\s*(\(.*\))?\s*/;
  //First Row is not a part of the header
  const headers = entries[0];
  const data = entries.slice(1);
  const indexes: any = [];
  const parsingHeaderErrors: string[] = [];

  const originalTemplateHeaders: headers = {
    [headers_values.isbn]: nonEmptyIsbnAndRightFormatValidator,
    [headers_values.number_of_loans]: optionalNumberValidator,
    [headers_values.license_duration]: optionalNumberValidator,
    [headers_values.concurrency]: optionalNumberValidator,
    [headers_values.loan_duration]: optionalNumberValidator,
    [headers_values.licenses_number]: numberOfLicensesAndRightQuantityValidator,
  };
  keysOriginalTemplateHeaders.value = Object.keys(originalTemplateHeaders);

  if (headers) {
    if (headers.length != Object.keys(headers_values).length) {
      return {
        success: false,
        errors: [`The headers are not the right format`],
      };
    }
    for (let i = 0; i < headers.length; i++) {
      const headerExtraction = rx.exec(headers[i]);
      if (!headerExtraction) {
        parsingHeaderErrors.push(
          `Unexpected header string, value:${headers[i]}`
        );
      } else {
        const header: string = headerExtraction[0]
          .toLowerCase()
          .replace(/\s/g, '_')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/_+$/, '');
        if (
          !keysOriginalTemplateHeaders.value.find(
            (element) => element === header
          )
        ) {
          parsingHeaderErrors.push(`Unknown header, value: ${header}`);
        } else {
          indexes[i] = header;
        }
      }
    }
    if (parsingHeaderErrors.length > 0) {
      return { errors: parsingHeaderErrors, success: false };
    }
    const parsingEntryErrors: ExcelEntryError[] = [];
    const parsingEntryWarnings: ExcelEntryWarning[] = [];
    const publicationLicensesList: any[] = [];
    if (data.length == 0) {
      return {
        success: false,
        errors: ['No data in file'],
      };
    }
    for (let i = 0; i < data.length; i++) {
      const entry = data[i];
      if (entry.length > headers.length) {
        parsingEntryErrors.push({
          message: 'No data in file',
          entry: i,
        });
      } else {
        const orderLicensesDataCandidate: { [key: string]: any } = {};
        for (let j = 0; j < headers.length; j++) {
          const validation = await originalTemplateHeaders[indexes[j]](
            entry[j]
          );
          if (validation.error != null) {
            parsingEntryErrors.push({
              message: validation.error,
              entry: i + 1,
              identifier: orderLicensesDataCandidate.isbn,
              header: indexes[j],
            });
          }
          if (validation.warning != null) {
            parsingEntryWarnings.push({
              message: validation.warning,
              entry: i + 1,
              identifier: orderLicensesDataCandidate.isbn,
              header: indexes[j],
            });
          }
          orderLicensesDataCandidate[indexes[j]] = validation.value;
        }
        publicationLicensesList.push({
          orderLicensesDataCandidate,
        });
      }
    }
    const totalLicensesInOrder = publicationLicensesList.reduce(
      (totalLicenceOrder, element): number =>
        totalLicenceOrder +
        element.orderLicensesDataCandidate[headers_values.licenses_number],
      0
    );
    if (totalLicensesInOrder > MAX_LICENSES_BEFORE_WARNING) {
      parsingEntryWarnings.unshift({
        message: `You will create ${totalLicensesInOrder} licenses in total`,
      });
    }
    return {
      success: parsingEntryErrors.length == 0,
      entries: publicationLicensesList,
      errors: parsingEntryErrors,
      warnings: parsingEntryWarnings,
    };
  }
  return {
    success: false,
    errors: ['The header fields are empty'],
  };
}

const answerAlertMessage = async (answer: boolean) => {
  if (!answer) {
    hideAlertMessage.value = true;
  } else {
    //Common parameters
    const namespaceId: string | undefined = namespaceSelectedRef.value?.id;

    if (!namespaceId) {
      toast.error('You must select a namespace.');
      return;
    }
    const source: string = 'https://warehouse.cantook.net';

    //Common protection default values
    const protection: protection = {
      devices: 6,
      copy: 25,
      print: 0,
      tts: true,
    };
    //Common restrictionType
    const restrictionType = 'LIMITED';
    const entries: LicenseSetInput[] = [];
    parseExcelResult.value?.entries.forEach((licenceRegister: any) => {
      const terms: terms = {
        concurrency:
          licenceRegister.orderLicensesDataCandidate[
            headers_values.concurrency
          ],
        checkouts:
          licenceRegister.orderLicensesDataCandidate[
            headers_values.number_of_loans
          ],
        expires: licenceRegister.orderLicensesDataCandidate[
          headers_values.license_duration
        ]
          ? dayjs()
              .add(
                licenceRegister.orderLicensesDataCandidate[
                  headers_values.license_duration
                ],
                'day'
              )
              .toISOString()
          : null,
        // Value in seconds
        length: licenceRegister.orderLicensesDataCandidate[
          headers_values.loan_duration
        ]
          ? licenceRegister.orderLicensesDataCandidate[
              headers_values.loan_duration
            ] * 86400
          : null,
        capLoanExpirationToLicense: false,
      };
      const entry: LicenseSetInput = {
        target: licenceRegister.orderLicensesDataCandidate.isbn,
        restrictionType: restrictionType,
        terms: terms,
        protection: protection,
        numberOfLicenses:
          licenceRegister.orderLicensesDataCandidate[
            headers_values.licenses_number
          ],
      };
      entries.push(entry);
    });

    const result = await createLicenses(namespaceId, source, entries);
    if (result.data.createLicenses.success) {
      readingMetadataSuccess.value = false;
      hideAlertMessage.value = true;
      const totalLicenses = result.data.createLicenses.numberOfCreatedLicenses;
      messageBodyInfo.value = `${
        totalLicenses > 1
          ? `${totalLicenses} licenses have been created`
          : `1 license has been created`
      }`;
      creatingLicensesSuccess.value = true;
      hideInfoMessage.value = false;
    } else {
      serverErrors.value = 'Try it again later.';
    }
    hideAlertMessage.value = true;
  }
};

const answerInfoMessage = async (answer: boolean) => {
  if (answer) {
    hideInfoMessage.value = true;
  }
};

const handleUploadedExcel = async (file: any) => {
  parseExcelResult.value = null;
  readingMetadataSuccess.value = false;
  loading.value = true;
  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data, {
    cellDates: true,
    dateNF: 'dd"/"mm"/"yyyy',
  });
  const excelRawData = XLSX.utils.sheet_to_json(
    workbook.Sheets[workbook.SheetNames[0]],
    { header: 1, blankrows: false, raw: false }
  );
  let temporalResult: ParseExcelResult = await parseExcel(excelRawData);
  if (temporalResult.success) {
    const MAX_LICENSES_TYPES_ALLOWED = 200;
    if (temporalResult.entries.length > MAX_LICENSES_TYPES_ALLOWED) {
      temporalResult.success = false;
      temporalResult.errors?.push({
        message: `The maximum number of licenses types allowed to be created at the same time is ${MAX_LICENSES_TYPES_ALLOWED} but you are requesting to create ${temporalResult.entries.length} sets of licenses.`,
      });
    } else {
      const isbns = temporalResult.entries.map(
        (publication: { orderLicensesDataCandidate: { isbn: string } }) =>
          publication.orderLicensesDataCandidate.isbn
      );
      const result = await getPublications(isbns);
      if (result.errors) {
      } else {
        if (Array.isArray(result.data.publications)) {
          const dictionaryResultPublications = Object.assign(
            {},
            ...result.data.publications.map(
              (publication: { isbn: string }) => ({
                [publication.isbn]: publication,
              })
            )
          );
          temporalResult.entries.forEach(
            (publication: {
              orderLicensesDataCandidate: { isbn: any; entityId: string };
            }) => {
              if (
                !(
                  publication.orderLicensesDataCandidate.isbn in
                  dictionaryResultPublications
                )
              ) {
                temporalResult.success = false;
                temporalResult.errors?.push({
                  identifier: publication.orderLicensesDataCandidate.isbn,
                  message: `This publication doesn't exist`,
                });
              } else {
                const isbn = publication.orderLicensesDataCandidate.isbn;
                //Adding publication id
                if (temporalResult.success) {
                  publication.orderLicensesDataCandidate.entityId =
                    dictionaryResultPublications[isbn].id;
                }
              }
            }
          );
        }
      }
    }
  }
  loading.value = false;
  parseExcelResult.value = temporalResult;
  readingMetadataSuccess.value = parseExcelResult.value.success;
};

function createLicensesPerform() {
  hideAlertMessage.value = false;
}

function formatTableHeader(parameter: string) {
  return parameter.replace(/_/g, ' ');
}
</script>

<style>
.tab-header {
  @apply p-3 text-gray-700 bg-gray-100 font-semibold text-sm tracking-wider uppercase text-center w-1/3;
}
</style>
