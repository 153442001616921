<template>
  <template v-if="props.loading"> Loading ... </template>
  <template v-else-if="props.licenseList">
    <Table
      center-header
      :headers="headers"
      :hasNextPage="props.pageInfo?.hasNextPage"
      :hasPreviousPage="props.pageInfo?.hasPreviousPage"
      @changePage="(e) => (e === 'next' ? emitNextPage() : emitPrevPage())"
    >
      <tr
        v-for="license of props.licenseList"
        :key="license.cursor"
        class="border-b border-gray-200 text-center even:bg-gray-50 min-w-full text-sm align-middle whitespace-nowrap table-fixed"
      >
        <td class="p-3">
          <p class="font-medium text-xs">
            <router-link
              :to="{
                name: 'license',
                params: { id: license.id },
              }"
              >{{ shortId(license.id).substring(0, 12) + '...' }}</router-link
            >
          </p>
        </td>
        <td class="p-3 text-center">
          <router-link
            :to="{
              name: 'namespace',
              params: { id: license.namespace.id },
            }"
          >
            {{ license.namespace.name }}
          </router-link>
        </td>
        <td class="p-3 text-center" v-if="props.entityId || props.target">
          <show-licenses-publication
            :target="license.target"
            :entity-id="license.publication?.id"
            :namespace-id="license.namespace.id"
            :load-cover="false"
            title-style="underline text-ellipsis overflow-hidden"
            header-style="hidden"
            cover-wrap-style="hidden"
            card-style="w-56 text-center"
            info-wrap-style="overflow-hidden justify-self-center"
            error-style="text-lg text-gray-700"
          />
        </td>
        <td>
          {{ getCustomAttributeValue(license.customAttributes, 'buyer_type') }}
        </td>
        <td>
          {{
            getCustomAttributeValue(
              license.customAttributes,
              'order_identifier'
            )
          }}
        </td>
        <td class="p-3 text-center">
          {{ formatteDate(license.created) }}
        </td>
        <td class="p-3 text-center">
          {{ formatteDate(license.updated) }}
        </td>
        <template v-if="license.terms">
          <td class="p-3 text-center">
            <span v-if="isExpired(license.terms.expires)"> Expired </span>
            <span v-else>
              {{ formatteDate(license.terms.expires) }}
            </span>
          </td>
          <td v-if="hasLicensesWriteRight" class="p-3 text-center">
            <div class="space-x-1">
              <div class="space-x-1 flex justify-center">
                <div class="inline-flex items-center space-x-4">
                  <div class="group inline-block relative">
                    <input
                      v-model="props.selectedLicensesToCancel"
                      :value="license.id"
                      :disabled="isCancelled(license.status)"
                      type="checkbox"
                      id="switch-selected-license"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      :class="
                        isCancelled(license.status) ? 'cursor-not-allowed' : ''
                      "
                      @click="emitLicenseSelectedToCancel(license.id)"
                    />
                    <div
                      v-if="isCancelled(license.status)"
                      class="invisible absolute left-1/2 -ml-20 w-40 will-change-auto bottom-full pb-0.5 z-1 translate-y-2 duration-50 origin-bottom scale-75 flex flex-col justify-center items-center opacity-75 transition ease-out group-hover:visible group-hover:scale-100 group-hover:translate-y-0 group-hover:opacity-100"
                    >
                      <div
                        class="flex-none rounded-lg px-2.5 py-2 text-xs font-semibold bg-gray-800 text-gray-50 dark:bg-gray-700 text-center"
                      >
                        License already cancelled
                      </div>
                      <div
                        class="flex-none w-0 h-0 border-l-4 border-l-transparent border-t-4 border-t-gray-800 border-r-4 border-r-transparent dark:border-t-gray-700"
                        aria-hidden="true"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </template>
      </tr>
    </Table>
  </template>
</template>

<script setup lang="ts">
import ArrowLeftIcon from '@heroicons/vue/solid/ArrowLeftIcon';
import ArrowRightIcon from '@heroicons/vue/solid/ArrowRightIcon';
import dayjs from 'dayjs';
import { LocationQueryValue } from 'vue-router';
import { shortId } from '../../utils';
import { PageInfo } from '../../__generated__/graphql';
import Table from '../base/Table.vue';
import ShowLicensesPublication from '../licenses/publications/ShowLicensesPublication.vue';

interface customAttribute {
  key: string;
  value: String;
}

const props = defineProps({
  licenseList: { type: Object, required: true },
  loading: { type: Boolean, required: true },
  entityId: {
    type: Object as () => LocationQueryValue,
    required: false,
  },
  target: {
    type: Object as () => LocationQueryValue,
    required: false,
  },
  pageInfo: { type: Object as () => PageInfo | null, required: true },
  selectedLicensesToCancel: { type: Array, required: true },
  hasLicensesWriteRight: { type: Boolean, required: false, default: true },
});

const headers = ['ID', 'Namespace']
  .concat(props.entityId || props.target ? ['Publication'] : [])
  .concat([
    'Buyer Type',
    'Order Identifier',
    'Created Date',
    'Updated Date',
    'Expire Date',
  ])
  .concat(props.hasLicensesWriteRight ? ['Cancel License'] : []);

const formatteDate = (date: string): string => {
  return date == null ? 'Never' : date.substring(0, 10);
};

const isExpired = (expireDate: string): boolean => {
  return dayjs(expireDate) < dayjs();
};

const isCancelled = (licenseStatus: string): boolean => {
  return licenseStatus === 'CANCELLED' ? true : false;
};

const getCustomAttributeValue = (
  customAttributesArray: Array<customAttribute>,
  keyCustomAttributevalue: string
) => {
  if (customAttributesArray) {
    const result = customAttributesArray.find(
      (attribute: customAttribute) => attribute.key == keyCustomAttributevalue
    );
    return result ? result.value : '';
  }
  return '';
};

const emits = defineEmits<{
  (e: 'next-page', endCursor: string | null): void;
  (e: 'previous-page', startCursor: string | null): void;
  (e: 'license-selected-to-cancel', licenseId: string): void;
}>();

const emitNextPage = () => {
  if (props.pageInfo) {
    emits('next-page', props.pageInfo.endCursor ?? null);
  }
};

const emitPrevPage = () => {
  if (props.pageInfo) {
    emits('previous-page', props.pageInfo.startCursor ?? null);
  }
};

const emitLicenseSelectedToCancel = (licenseId: string) => {
  emits('license-selected-to-cancel', licenseId);
};
</script>
