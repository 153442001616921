<template>
  <message-confirmation
    :isHidden="hideCancelAlertMessage"
    :bodyInfoMessage="messageCancelAlertBody"
    titleInfoMessage="Cancel Licenses"
    buttonConfirmMessage="Yes, cancel license/s"
    :showCancelButton="true"
    variant="alert"
    @answerUser="answerCancelAlertMessage"
  />

  <h2 class="page-title">Licenses</h2>

  <namespace-selector
    v-if="!urlQueryHasEntities"
    v-model="namespaceSelectedRef"
    resource-type="licenses"
    inject-option-all-namespaces
    @update:model-value="updateRouteQuery()"
  />

  <template v-if="activeLicensesLoading"> Loading...</template>
  <template v-else-if="activeLicensesError">
    <error-display
      class="my-2"
      headerMessage="Error"
      :error="activeLicensesError"
    />
  </template>

  <template v-else-if="activeLicenses">
    <template v-if="entityId">
      <show-licenses-publication
        :entityId="entityId"
        :target="target"
        card-style="py-4 px-5 lg:px-6 w-full bg-gray-50 mb-4"
        header-style="font-semibold text-2xl"
        title-style="text-gray-500 text-sm font-medium tracking-wider"
        target-style="text-gray-500 tracking-wider"
        loading-style="text-lg text-gray-500"
        error-style="text-lg text-gray-700"
        link-style="flex flex-row"
        cover-wrap-style="mr-4 h-[100px]"
        cover-style="h-full object-contain"
        load-cover
      />
    </template>
    <div
      class="border border-gray-200 rounded overflow-x-auto min-w-full bg-white"
    >
      <div
        class="p-5 grow border-b flex justify-end border-gray-100 dark:border-gray-700"
      >
        <div class="relative w-3/6">
          <div
            class="absolute inset-y-0 left-0 w-10 my-px ml-px flex items-center justify-center pointer-events-none rounded-l text-gray-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="hi-mini hi-magnifying-glass inline-block w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
              />
            </svg>
          </div>
          <input
            class="w-full block border placeholder-gray-400 pl-10 pr-3 py-2 leading-6 text-sm rounded border-gray-200 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 dark:bg-gray-900/25 dark:border-gray-700 dark:focus:border-blue-500"
            type="text"
            id="search"
            name="search"
            v-model="searchKeyword"
            @keydown.enter="searchKeywordEntered"
            placeholder="Filter licenses by order identifier.."
          />
        </div>
      </div>
      <div
        class="flex flex-col rounded-lg shadow-sm bg-white dark:text-gray-100 dark:bg-gray-800"
      >
        <div
          class="flex items-center border-b border-gray-200/75 text-sm dark:border-gray-700"
        >
          <button
            type="button"
            id="active-tab"
            role="tab"
            aria-controls="active-tab-pane"
            :aria-selected="activeTabOpen"
            class="px-3 md:px-5 py-3 font-medium -mb-px flex items-center space-x-2 border-b-2"
            @click="onActiveTabClicked()"
          >
            Active
          </button>
          <button
            type="button"
            id="inactive-tab"
            role="tab"
            aria-controls="inactive-tab-pane"
            :aria-selected="!activeTabOpen"
            class="px-3 md:px-5 py-3 font-medium -mb-px flex items-center space-x-2 border-b-2"
            @click="onInactiveTabClicked()"
          >
            Inactive
          </button>
        </div>

        <div class="grow">
          <div
            id="active-tab-pane"
            role="tabpanel"
            aria-labelledby="active-tab"
            tabindex="0"
          >
            <licenses-list
              v-if="activeTabOpen"
              :licenseList="activeLicenses"
              :loading="activeLicensesLoading"
              :entityId="entityId"
              :target="target"
              :pageInfo="activeLicensesPageInfo"
              :selected-licenses-to-cancel="selectedLicensesToCancel"
              :hasLicensesWriteRight
              @next-page="activeNextPage($event)"
              @previous-page="activePreviousPage($event)"
              @license-selected-to-cancel="addLicenseToCancelList($event)"
            />
          </div>
          <div
            id="inactive-tab-pane"
            role="tabpanel"
            aria-labelledby="inactive-tab"
            tabindex="0"
          >
            <licenses-list
              v-if="!activeTabOpen && inactiveLicenses"
              :licenseList="inactiveLicenses"
              :loading="inactiveLicensesLoading"
              :entityId="entityId"
              :target="target"
              :pageInfo="inactiveLicensesPageInfo"
              :selected-licenses-to-cancel="selectedLicensesToCancel"
              :hasLicensesWriteRight
              @next-page="inactiveNextPage($event)"
              @previous-page="inactivePreviousPage($event)"
              @license-selected-to-cancel="addLicenseToCancelList($event)"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="hasLicensesWriteRight"
      class="flex-grow w-full flex justify-end margin-space my-4"
    >
      <div class="flex justify-items-center">
        <dm-button
          class="m-1"
          id="create-upload-publications"
          variant="danger"
          size="xL"
          :enabled="enableLicensesToCancelButton"
          :loading="cancelLicensesLoading"
          @click="cancelLicenses()"
          >Cancel license/s
        </dm-button>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import {
  DefaultApolloClient,
  useLazyQuery,
  useQuery,
} from '@vue/apollo-composable';
import { computed, inject, ref, Ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { NormalizedCacheObject } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { useToast } from 'vue-toastification';

import { graphql } from '../../__generated__/gql';

import MessageConfirmation from '../base/DialogMessage.vue';
import ErrorDisplay from '../ErrorDisplay.vue';
import NamespaceSelector from '../namespaces/NamespaceSelector.vue';
import LicensesList from './LicensesList.vue';
import { useAuth } from '../../auth';
import ShowLicensesPublication from './publications/ShowLicensesPublication.vue';

const { canWriteResourceTypeOnAtLeastOneNamespace } = useAuth();

const hasLicensesWriteRight =
  canWriteResourceTypeOnAtLeastOneNamespace('licenses');

const route = useRoute();
const router = useRouter();
/*Licenses in each page  */
const licensesPerPage = 10;
/*Filter*/
const searchKeyword = ref('');
/*Filter */
const entityId = computed(() =>
  Array.isArray(route.query.entity) ? route.query.entity[0] : route.query.entity
);
const target = computed(() =>
  Array.isArray(route.query.target) ? route.query.target[0] : route.query.target
);

const toast = useToast();

/*Cancel licenses*/
const selectedLicensesToCancel: Ref<string[]> = ref([]);
const cancelLicensesLoading = ref(false);
const hideCancelAlertMessage = ref(true);
const errorCancellingLicenses = ref(false);

const enableLicensesToCancelButton = computed(
  () => selectedLicensesToCancel.value.length > 0
);

const messageCancelAlertBody = computed(
  () =>
    'Are you sure you want to cancel ' +
    selectedLicensesToCancel.value.length +
    ' license/s?'
);

const cancelLicenses = () => {
  hideCancelAlertMessage.value = false;
};

const addLicenseToCancelList = (licenseId: string) => {
  if (!selectedLicensesToCancel.value.includes(licenseId)) {
    selectedLicensesToCancel.value.push(licenseId);
  } else {
    selectedLicensesToCancel.value.splice(
      selectedLicensesToCancel.value.indexOf(licenseId),
      1
    );
  }
};

const answerCancelAlertMessage = async (answer: boolean) => {
  if (answer) {
    cancelLicensesLoading.value = true;
    const resultCancelling = await apolloClient.mutate({
      mutation: graphql(`
        mutation cancelLicenses($ids: [ID!]!) {
          licenses: cancelLicenses(ids: $ids) {
            license {
              ...ShowLicensePage
            }
          }
        }
      `),
      variables: { ids: selectedLicensesToCancel.value },
    });
    cancelLicensesLoading.value = false;

    selectedLicensesToCancel.value = [];
    if (!resultCancelling?.data) {
      errorCancellingLicenses.value = true;
    } else {
      activeLicensesRefetch();
      toast.success('License/s cancelled successfully');
    }
  }
  hideCancelAlertMessage.value = true;
};

/* Namespaces */
const urlQueryHasEntities = computed(() => !!route.query.entity);
const urlQueryHasNamespaces = computed(() => !!route.query.namespaceId);
const namespaceSelectedRef: Ref<{ id: string; name: string } | null> =
  ref(null);

const namespaceId = computed(() =>
  !urlQueryHasNamespaces.value
    ? namespaceSelectedRef.value?.id
    : Array.isArray(route.query.namespaceId)
    ? route.query.namespaceId[0]
    : route.query.namespaceId
);

const updateRouteQuery = () => {
  router.push({
    query: {
      namespaceId: namespaceSelectedRef.value?.id,
      namespace: namespaceSelectedRef.value?.name,
    },
  });
};

watch(
  () => route.query,
  (newValue) => {
    namespaceSelectedRef.value = {
      id: newValue.namespaceId as string,
      name: newValue.namespace as string,
    };
  }
);

const apolloClient = inject(
  DefaultApolloClient
) as ApolloClient<NormalizedCacheObject>;

/*variables for licenses query */
const variables = computed(() => ({
  activeFirst: licensesPerPage,
  activeLast: null,
  activeBefore: undefined,
  activeAfter: undefined,
  inactiveFirst: licensesPerPage,
  inactiveLast: null,
  inactiveBefore: undefined,
  inactiveAfter: undefined,
  filterBy: {
    target: target.value,
    namespaceId: namespaceId.value,
    ...(searchKeyword.value
      ? {
          customAttribute: {
            key: 'order_identifier',
            value: searchKeyword.value,
          },
        }
      : {}),
  },
}));
/*Query structure */
const _LICENSE_FRAGMENT = graphql(`
  fragment ShowLicensePage on License {
    id
    target
    status
    source
    namespace {
      id
      name
    }
    created
    updated
    terms {
      checkouts
      concurrency
      expires
    }
    customAttributes {
      key
      value
    }
  }
`);

/*Licenses*/
const {
  result: activeLicensesResult,
  loading: activeLicensesLoading,
  fetchMore: activeLicensesFetchMore,
  refetch: activeLicensesRefetch,
  error: activeLicensesError,
} = useQuery(
  graphql(`
    query GetActiveLicenses(
      $activeFirst: Int
      $activeLast: Int
      $activeAfter: String
      $activeBefore: String
      $filterBy: LicenseFilter
    ) {
      activeLicenses(
        first: $activeFirst
        last: $activeLast
        after: $activeAfter
        before: $activeBefore
        orderBy: { direction: DESC, field: UPDATED_AT }
        filterBy: $filterBy
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          endCursor
          startCursor
        }
        edges {
          cursor
          node {
            ...ShowLicensePage
          }
        }
      }
    }
  `),
  variables
);

const {
  load,
  result: inactiveLicensesResult,
  loading: inactiveLicensesLoading,
  fetchMore: inactiveLicensesFetchMore,
  error: inactiveLicensesError,
} = useLazyQuery(
  graphql(`
    query GetInactiveLicenses(
      $inactiveFirst: Int
      $inactiveLast: Int
      $inactiveAfter: String
      $inactiveBefore: String
      $filterBy: LicenseFilter
    ) {
      inactiveLicenses(
        first: $inactiveFirst
        last: $inactiveLast
        after: $inactiveAfter
        before: $inactiveBefore
        orderBy: { direction: DESC, field: UPDATED_AT }
        filterBy: $filterBy
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          endCursor
          startCursor
        }
        edges {
          cursor
          node {
            ...ShowLicensePage
          }
        }
      }
    }
  `),
  variables
);

const activeLicenses = computed(
  () =>
    activeLicensesResult.value?.activeLicenses.edges.map((edge) => edge.node) ??
    []
);

const inactiveLicenses = computed(
  () =>
    inactiveLicensesResult.value?.inactiveLicenses.edges.map(
      (edge) => edge.node
    ) ?? []
);

const activeLicensesPageInfo = computed(
  () => activeLicensesResult.value?.activeLicenses.pageInfo ?? null
);

const inactiveLicensesPageInfo = computed(
  () => inactiveLicensesResult.value?.inactiveLicenses.pageInfo ?? null
);

const activeTabOpen = ref(true);

const onActiveTabClicked = () => {
  activeTabOpen.value = true;
  selectedLicensesToCancel.value = [];
};

const onInactiveTabClicked = () => {
  activeTabOpen.value = false;
  load();
  selectedLicensesToCancel.value = [];
};

/*Filter */
const searchKeywordEntered = async (entry: any) => {
  searchKeyword.value != ''
    ? (variables.value.filterBy = {
        target: target.value,
        namespaceId: namespaceId.value,
        customAttribute: {
          key: 'order_identifier',
          value: searchKeyword.value,
        },
      })
    : (variables.value.filterBy = {
        target: target.value,
        namespaceId: namespaceId.value,
      });
};

/*Pagination */
function activeNextPage(endCursor: string | null) {
  activeLicensesFetchMore({
    variables: {
      activeFirst: licensesPerPage,
      activeLast: null,
      activeAfter: endCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

function activePreviousPage(startCursor: string | null) {
  activeLicensesFetchMore({
    variables: {
      activeLast: licensesPerPage,
      activeFirst: null,
      activeBefore: startCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

function inactiveNextPage(endCursor: string | null) {
  inactiveLicensesFetchMore({
    variables: {
      inactiveFirst: licensesPerPage,
      inactiveLast: null,
      inactiveAfter: endCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

function inactivePreviousPage(startCursor: string | null) {
  inactiveLicensesFetchMore({
    variables: {
      inactiveLast: licensesPerPage,
      inactiveFirst: null,
      inactiveBefore: startCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}
</script>
<style>
[aria-selected='true'] {
  @apply text-indigo-600;
  @apply border-indigo-500;
  @apply dark:text-indigo-400;
  @apply dark:border-indigo-500;
}
</style>
